<template>
    <div class="home">
        <topHeader></topHeader>
        <el-card class="box-card">
            <span class="title">FCP后台管理系统</span>
            <!-- <el-button type="primary" class="moreInfo" @click="jumpToHome">跳转至主站</el-button> -->
            <p class="hr"></p>

            <el-row class="buttonRow">
                <el-button type="success" size="medium" @click="open()">设置密码</el-button>
                <el-button type="warning" size="medium" @click="editAccount(0)">停用账号</el-button>
                <el-button type="danger" size="medium" @click="editAccount(2)">删除账号</el-button>
                <span class="activeSpan">按激活状态:&nbsp;</span> 
                <el-select v-model="statusValue" clearable placeholder="请选择" size="small">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>

                <span class="activeSpan">按用户姓名:&nbsp;</span>
                <el-input v-model="keywords" placeholder="请输入姓名，可模糊搜索" size="small" style="width:180px;margin-right: 30px;"></el-input>
                <el-button icon="el-icon-search" @click="getAccounts()" circle></el-button>
            </el-row>


            <el-table :data="tableData" style="width: 100%;" @selection-change="handleSelectionChange" ref="multipleTable">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="custCode" label="用户账号">
                </el-table-column>
                <el-table-column prop="custPass" label="用户密码">
                    <template slot-scope="scope">
                        <span v-if="scope.row.custPass != null && scope.row.custPass != ''">{{scope.row.custPass}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column prop="custName" label="用户姓名" sortable>
                </el-table-column>
                <el-table-column prop="custAbbr" label="用户简称">
                </el-table-column>
                <el-table-column prop="updateTime" label="上次登录日期" sortable>
                    <template slot-scope="scope">
                        <span v-if="scope.row.updateTime != null && scope.row.updateTime != ''">{{formatDate(scope.row.updateTime)}}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-tag type="success" v-if="scope.row.custPass != null && scope.row.custPass != ''">使用中</el-tag>
                        <el-tag type="info" v-else>待激活</el-tag>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import topHeader from '../../components/TopHeader.vue'
import { adminAPI } from "@/api/modules/admin";
import { get, post } from "@/api/request";

export default {
    name: 'myMenu',
    components: {
        topHeader
    },
    data() {
        return {
            // 用户账号list
            tableData: [],
            // 表单多选按钮的值
            multipleSelection: [],
            // 密码值
            password: '',
            // 是否激活的值
            statusValue:'',
            // 是否激活下拉框
            options:[{
            value:1,
            label:'已激活'
            },
            {
            value:0,
            label:'未激活'
            }],
            // 搜索关键词
            keywords:'',
        }
    },
    created() {
        this.initData()
    },
    methods: {
        // 获取初始化数据
        initData() {
            this.getAllAccounts()
        },
        // 获取所有FCP用户
        getAllAccounts() {
            // 发起请求
            get(adminAPI.getAllAccounts)
                .then((res) => {
                    // 请求成功时
                    if (res.data.code === 0) {
                        this.tableData = res.data.data;
                        // 失败
                    } else {
                        let mg = res.data.msg;
                        let tp = 'error';
                        this.$message({ message: mg, type: tp });
                    }
                })
                .catch(res => {
                    let mg = res.data.msg;
                    let tp = 'error';
                    this.$message({ message: mg, type: tp });
                })
        },
        // 跳转主站按钮
        jumpToHome() {
            this.$router.push('/home');
            
        },
        // 时间转换
        formatDate(row) {
            return this.helper.toTime(row)
        },
        // 表单多选方法
        handleSelectionChange(val) {
            this.multipleSelection = val.map(item => item.custId)
        },
        // 设置密码的弹窗
        open() {
            if (this.multipleSelection.length == 0) {
                this.$message({ message: '请先选择至少一条数据！', type: 'error' });
                return;
            }
            this.$prompt('至少输入一个大写和小写字母 长度8~16位', '请设置密码', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~!@&%#_]{8,16}$/,
                inputErrorMessage: '密码格式不正确'
            }).then(({ value }) => {
                this.password = value;
                this.editAccount(1);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        // 修改账号状态
        editAccount(type) {
            if (this.multipleSelection.length == 0) {
                this.$message({ message: '请先选择至少一条数据！', type: 'error' });
                return;
            }
            // 发起请求
            post(adminAPI.editAccountsStatus, {
                customer_id_list: this.multipleSelection,
                editType: type,
                password: this.password,
            })
                .then((res) => {
                    // 请求成功时
                    if (res.data.code === 0) {
                        let mg = res.data.msg;
                        let tp = 'success';
                        this.$message({ message: mg, type: tp });
                        this.$refs.multipleTable.clearSelection();
                        this.getAllAccounts();
                    } else {
                        let mg = res.data.msg;
                        let tp = 'error';
                        this.$message({ message: mg, type: tp });
                    }
                })
                .catch(res => {
                    let mg = res.data.msg;
                    let tp = 'error';
                    this.$message({ message: mg, type: tp });
                })
        },
        // 筛选用户的方法
        getAccounts() {
            // 发起请求
            get(adminAPI.getAccounts,{
                keywords:this.keywords,
                status:this.statusValue
            })
                .then((res) => {
                    // 请求成功时
                    if (res.data.code === 0) {
                        this.tableData = res.data.data;
                        // 失败
                    } else {
                        let mg = res.data.msg;
                        let tp = 'error';
                        this.$message({ message: mg, type: tp });
                    }
                })
                .catch(res => {
                    let mg = res.data.msg;
                    let tp = 'error';
                    this.$message({ message: mg, type: tp });
                })
        },
    }
}


</script>

<style>
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both
}

.box-card {
    height: 100%;
    margin: 20px 40px;
    padding-bottom: 100px;
}

/* 标题 */
.title {
    font-size: x-large;
    color: rgb(110, 110, 110);
    float: left;
    font-weight: bolder;
    margin: 10px 10px 30px 10px;
}

/* 标题后的说明 */
.title+span {
    color: grey;
    float: left;
    font-size: 14px;
    margin: 18px 10px 20px 10px;
}

/* 分割线 */
.hr {
    border-bottom: 1px solid grey;
    margin-top: 55px;
}

/* 跳转主站按钮 */
.moreInfo {
    padding: 8px;
    float: right;
    margin: 10px 20px 0 0;
}

/* 操作按钮行 */
.buttonRow {
    float: left;
    margin-top: 10px;
    margin-bottom: 20px;
}
/* 激活状态按钮 */
.activeSpan{
    margin-left: 30px;
    font-size: 16px;
}
</style>