import axios from "axios";
import store from '../store/index'
import router from "@/router";
axios.defaults.timeout = 60000;  //设置接口超时时间
//axios.defaults.baseURL = "http://192.168.1.251/erp"
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; //根据环境设置基础路径/
axios.defaults.withCredentials = true;
// http://192.168.1.247:8080
axios.defaults.headers.post["Content-Type"] =
    "application/json";
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 判断是否存在token,如果存在将每个页面header添加token
    if (localStorage.getItem("token")) {
        config.headers.common['Authorization'] =  localStorage.getItem("token")
    }
    return config;
}, function (error) {
    //router.push('/login')
    return Promise.reject(error)
})

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时候携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params
        }).then(res => {
            resolve(res);
        }).catch((msg) => console.error(msg));
    });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时候携带的参数]
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url,params)
            .then(res => {
                resolve(res);
            }).catch((msg) => console.error(msg));
    });
}