import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/components/IndexBody.vue'),
    children: [
      {
        path: '/sample_list',
        name: 'sample_list',
        component: () => import('@/views/SamplesList.vue')
      },
      {
        path: '/home',
        name: 'Home',
        require: true,
        component: () => import('@/views/SalesContract.vue')
      },
      {
        path: '/material_list',
        name: 'material_list',
        component: () => import('@/views/MaterialsList.vue')
      },
      {
        path: '/chewer_list',
        name: 'chewer_list',
        component: () => import('@/views/ChewerList.vue')
      },
      {
        path: '/SHBark/SalesContract',
        name: 'SalesContract',
        component: () => import('@/views/SalesContract.vue')
      },
      {
        path: '/SHBark/Procurement',
        name: 'Procurement',
        component: () => import('@/views/Procurement.vue')
      },
      {
        path: '/SHBark/Shipping',
        name: 'Shipping',
        component: () => import('@/views/Shipping.vue')
      },
      {
        path: '/SHBark/WeeklyPOReport',
        name: 'WeeklyPOReport',
        component: () => import('@/views/WeeklyPOReport.vue')
      }
    ]
  },

  {
    path: '/admin',
    name: 'AdminLogin',
    require: true,
    component: () => import('@/views/admin/adminLogin.vue'),
    meta: { title: '管理员登录' }
  },
  {
    path: '/admin_home',
    name: 'admin_home',
    require: true,
    component: () => import('@/views/admin/adminHome.vue'),
    meta: { title: '后台首页' }
  },
  {
    path: '/login',
    name: 'Login',
    require: true,
    component: () => import('@/views/Login.vue'),
    meta: { title: '登录' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => console.error(err));
// };
router.beforeEach((to, from, next) => {
  if (to.fullPath === '/admin') {
    next();
  } else {
    if (localStorage.getItem('token')) {
      next();
    } else {
      if (to.fullPath === '/login') {
        next();
      } else {
        next({ path: '/login' });
      }
    }
  }
});
export default router;
