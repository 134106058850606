import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import adminHome from './views/admin/adminHome';
import helper from './assets/pubjs/helper.js';
import './assets/pubcss/variables.scss';
import './assets/pubcss/global.scss';
import locale from 'element-ui/lib/locale/lang/en';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(adminHome);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(ElementUI, { locale }); // 将elementUI改为英文

Vue.config.productionTip = false;
Vue.prototype.helper = helper;
Vue.prototype.jump = (p, q) => {
  router.push({ path: p, query: q }).catch(err => err);
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
