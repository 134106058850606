import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: 'userName',
    token: '',
  },
  mutations: {
    setUserName(state,value) {
      state.userName = value;
    },
    setToken(state,value) {
      state.token = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
