<template>
	<div class="vd_header">
		<div class="logo">
			<span class="logoSpan">Customer Center</span>
		</div>
		<el-dropdown v-if="showUser()" trigger="click" @command="handleCommand" style="margin-left: auto">
			<el-link class="el-dropdown-link vg_pointer vd_font_color vd_cpName" :underline="false">
				<span class="el-icon-s-custom"></span>
				{{ userName }}
				<i class="el-icon-caret-bottom iconPic"></i>
			</el-link>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item command="dashboard"><i class="el-icon-s-home"></i>Home</el-dropdown-item>
				<el-dropdown-item command="logout"><i class="el-icon-switch-button iconPic"></i>
					Logout
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { userAPI } from "@api/modules/user";
import { get } from "@api/request";
export default {
	name: "TopHeader",
	props: {},
	data() {
		return {
			userName: "userName",
			new: {},
			// logoUrl:require("@/assets/image/logo.png"),
		};
	},
	created() {
		this.userName = localStorage.getItem("userName")
	},
	methods: {
		showUser(){
			return this.$route.path !== '/login';
		},
		// 用户名下拉菜单选择事件
		handleCommand(command) {
			if (command === "logout") {
				// 如果能拿到本地的token则删除，没有也不影响注销
				if (localStorage.getItem("loginInfo")) {
					localStorage.removeItem("loginInfo")
				}
				if(this.$route.path ==='/admin_home'){
					this.loginOut(2);
				}else{
					this.loginOut(1);
				}
			} else if (command === 'dashboard') {
				this.jump('/home')
			}
		},
		loginOut(val){
			// 发起注销请求，不考虑返回结果直接回登录页面
			get(userAPI.userLogout,"").then(() => {
				if(val === 1){
					this.$router.push('/login');
				}else if(val === 2){
					this.$router.push('/admin');
				}
			});
		}
	},
};
</script>

<style scoped lang="scss">
.vd_header{
	background-color: rgb(3 3 3);
	display: flex;
	height: 70px;
	justify-content: center;
	align-items: center;
	padding-left: 25px;
	padding-right: 25px;
}
.vg_pointer{
	padding-top: 10px;
}
.vg_pointer_img{
	padding-top: 5px;
	width: 204px;
	height: 70px;
}
.vd_cpName {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-weight:normal;
	color: white;
}
.vd_cpName :hover{
	color: white;
}

.vd_font_color {
	letter-spacing: 2px;
	padding-left: 16px;
}

// 网站大标题
.vg_ml_8 {
	font-size: 18px;
	margin-top: 4px;
	margin-left: 10px;
	font-weight: bolder;
}
// logout图标
.iconPic{
	font-weight: bolder;
	color: black;
}
// 用户名旁边的下拉剪头
.el-icon-caret-bottom{
	color: white;
}

.logo {
	font-size: 30px;
	letter-spacing: 0;
	color: white;
}

.logoSpan {
}
</style>
