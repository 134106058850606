// import VueCookies from 'vue-cookies';
// import Vue from 'vue';
// Vue.use(VueCookies)
// import VueCookies from 'vue-cookies'; import Vue from 'vue'; Vue.use(VueCookies)
// import el from "element-ui/src/locale/lang/el";
// import axios from "axios";
import {Number} from "core-js";

export default {
  //将json或Object转化成FormData
  toFormData(param) {
    let formData = new FormData();
    Object.keys(param).forEach((key) => {
      formData.append(key, param[key]);
    });
    return formData;
  },
  //过滤后只保留英语字母和数字
  keepEngNum(str) {
    return str.replace(/[^0-9a-zA-Z]/g, '');
  },
  //过滤后只保留英语字母和数字和_
  keepEngNumG(str) {
    return str.replace(/[^0-9a-zA-Z_]/g, '');
  },
  //过滤后只保留英语字母和数字-
  keepEngNum3(str) {
    return str.replace(/[^\u4E00-\u9FA50-9a-zA-Z-]/g, '');
  },
  //只输入数字
  pureNumber(str) {
    return str.replace(/[^0-9Xx]/g, '')
  },
  //过滤后只保留英语字母和数字和小数点加-加_@
  keepEngNum2(str) {
    return str.replace(/[^0-9a-zA-Z-_@.]/g, '');
  },
  //只能输入英文
  pureE(str) {
    return str.replace(/[^A-Za-z]/g, '')
  },
  // 只能输入汉字 
  pureC(str) {
    return str.replace(/[^\u4E00-\u9FA5]/g, '')
  },
  // 只能输入电话
  purephone(str) {
    return str.replace(/[^0-9-]/g, '')
  },
  // 过滤后只保留数字和.
  keepTNum1(number) {
    return number.replace(/[^0-9.]/g, '')
  },
  // 过滤后只保留数字和.
  keepTNum2(number) {
    return number.replace(/[^0-9.-]/g, '')
  },
  // 只能输入汉语
  keepChin(str) {
    return str.replace(/[^\u4e00-\u9fa5]/g, '')
  },
  // 限定输入正整数数字 ---用于公积金账号
  keepTNum(number) {
    return number.replace(/\D/g, '')
  },
  // 不可空格
  keepSpace2(number) {
    if (/\s/g.test(number)) {
      return number.substr(0, number.indexOf(' '))
    } else {
      return number
    }
  },
  // 限定不能输入空格
  keepSpace1(number) {
    if (/\s/g.test(number)) {
      return number.substr(0, number.indexOf(' '))
    } else {
      this.keepEngNumG(number)
    }
  },
  // 验证身份证信息
  statusId(number) {
    let ze = /[^0-9Xx]/g
    if (ze.test(number)) {
      return number
    } else {
      return ''
    }
  },
  //时间戳转换成显示时间(到秒)
  toTime(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return (Y + M + D + h + m + s);
  },
  //时间戳转换成显示时间(到日) FCP专用
  toTimeDay(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear();
    let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ', ';
    if(M=='01'){
      M='Jan, '
    }else if(M=="02"){
      M='Feb, '
    }else if(M=="03"){
      M='Mar, '
    }else if(M=="04"){
      M='Apr, '
    }else if(M=="05"){
      M='May, '
    }else if(M=="06"){
      M='Jun, '
    }else if(M=="07"){
      M='Jul, '
    }else if(M=="08"){
      M='Aug, '
    }else if(M=="09"){
      M='Sep, '
    }else if(M=="10"){
      M='Oct, '
    }else if(M=="11"){
      M='Nov, '
    }else if(M=="12"){
      M='Dec, '
    }
    return (M + D+ Y);
  },
  //时间戳转换成显示时间(到年)
  toTimeYear(time) {
    var date = new Date(parseInt(time) * 1000);
    let Y = date.getFullYear()
    return (Y);
  },
  // 国标时间转成时间戳 10 位
  toTimeVal(localTime) {
    return localTime = Math.round(new Date(localTime).getTime() / 1000);
  },

  // 获取时间开始时间和结束时间戳
  getTime(timeVal) {
    if (timeVal && timeVal.length === 2) {
      timeVal.startTime = timeVal[0]
      timeVal.endTime = timeVal[1]
      timeVal.startTime = Number(new Date(timeVal.startTime).getTime() / 1000)
      timeVal.endTime = Number(new Date(timeVal.endTime).getTime() / 1000)
      return timeVal
    } else {
      timeVal = []
      return timeVal
    }
  },

  // 计算司龄
  toSeniority(entryDate, leaveDate) {
    // if(leaveDate !==0){
    //  return entryDate = this.toLeaveSen(entryDate,leaveDate)
    // }
    let nowDate = new Date().getTime()
    entryDate = new Date(entryDate * 1000).getTime()
    if (leaveDate !== 0) {
      nowDate = new Date(leaveDate * 1000).getTime()
    }
    if (nowDate > entryDate) {
      let workDate = (nowDate - entryDate) / (60 * 60 * 24 * 30 * 1000);
      if (workDate >= 12) {
        // 一年以上
        if (workDate % 12 === 0) {
          return parseInt(workDate / 12) + "年"
        } else {
          return parseInt(workDate / 12) + "年" + parseInt(workDate % 12) + "个月"
        }
      } else if (parseInt(workDate) > 0) {
        return parseInt(workDate) + "个月"
      } else {
        return "未满一个月"
      }
    } else {
      return "日期填写不正确!"
    }
  },


  // 根据身份证号计算出生日期，年龄，性别
  mathUserInfo(stff_identity, num, birth, gender, age) {
    if (stff_identity.length === 18) {
      if (num === 1) {
        birth = stff_identity.substring(6, 10) + "-" + stff_identity.substring(10, 12) + "-" + stff_identity.substring(12, 14);
        return birth;
      }
      if (num === 2) {
        if (parseInt(stff_identity.substr(-2, 1)) % 2 === 1) {
          return gender = '男';
        } else {
          return gender = '女';
        }
      }
      if (num === 3) {
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        age = myDate.getFullYear() - stff_identity.substring(6, 10) - 1;
        if (stff_identity.substring(10, 12) < month || stff_identity.substring(10, 12) === month && stff_identity.substring(12, 14) <= day) {
          age++;
        }
        return age
      }
    } else {
      return age = null, birth = null, gender = null
    }

  },

  // 获取单个日期
  getNewDate(timeVal) {
    if (timeVal) {
      timeVal = Number(new Date(timeVal).getTime() / 1000)
      return timeVal
    } else {
      timeVal = null
      return timeVal
    }
  },
  // 时间转换为显示时间终极版--带分秒的
  toStringDate(rowVal) {
    if (rowVal) {
      return this.toTimeDay(rowVal)
    }
  },
  // 时间转换为显示时间终极版--整天的
  toStringDay(rowVal) {
    if (rowVal) {
      return this.toTimeDay(rowVal)
    }
  },
  // 数字字符串转成数组
  toArray(strArr) {
    strArr = strArr.split(",").map(Number)
    return strArr
  },
  // 拼接图片url地址
  picUrl(url, type) {
    let updateTime = new Date().getTime()
    let header = '/file'
    switch (process.env.VUE_APP_ENV) {
      case 'production':
        header = 'https://www.manenwork.com/dyjfile'
        break;
      case 'development':
        header = 'http://192.168.1.236/dyjfile'
        break;
      case 'test':
        header = 'https://www.petsprod.com/dyjfile'
        break;
      default:
        header = 'http://192.168.1.236/dyjfile';
        break;
    }
    switch (type) {
      case 'l':
        return header + url + '.jpg?' + updateTime;
      case 'm':
        return header + url + '_m.jpg?' + updateTime;
      case 's':
        return header + url + '_s.jpg?' + updateTime;
      default:
        return header + url;
    }
  },
  // 组合路径
  megPath(url) {
    let header = '/file'
    if (process.env.NODE_ENV === 'production') {
      // header = 'https://www.petsprod.com/erpfile'
      header = '/erpfile'
    }
    if (url) {
      return url = header + url
    } else {
      return url = header
    }
  },
  // 下载文件
  downloadItem(url, label) {
    let DownloadLink = document.createElement('a');
    DownloadLink.style = 'display: none'; // 创建一个隐藏的a标签
    DownloadLink.download = label;
    DownloadLink.href = url;
    document.body.appendChild(DownloadLink);
    DownloadLink.click(); // 触发a标签的click事件
    document.body.removeChild(DownloadLink);
    // const blob = new Blob([]);
    // const link = document.createElement("a");
    // link.href = URL.createObjectURL(blob);
    // link.download = label;
    // link.click();
    // URL.revokeObjectURL(link.href);
    // axios.get(url, { responseType: "blob" })
    //     .then(response => {
    //
    //     })
    //     .catch(console.error);
  },
  // 聘用类型
  reHireTap(type) {
    if (type === 1) {
      return '返聘'
    } else if (type === 2) {
      return '实习'
    } else if (type == 3) {
      return '劳务'
    } else if (type === 4) {
      return '正式'
    } else if (type == 5) {
      return '试用'
    } else if (type === 6) {
      return '兼职'
    } else {
      return '无生效合同'
    }
  },
  // 时间戳过来默认为0的状态
  isTimeStamp0(timeVal) {
    if (timeVal === 0 || timeVal === null) {
      return timeVal = null
    } else {
      return timeVal = timeVal * 1000
    }
  },
  // 控制状态删除
  isDelete(selection) {
    let statusList = selection.map(item => {
      return item.status
    })
    const allStatus = statusList.reduce(function (sum, number) {
      return sum + number;
    }, 0)
    if (allStatus > 0) {
      return selection = false
    } else {
      return selection = true
    }
  },
  modePrepend(val) {
    if (process.env.NODE_ENV === 'production') {
      return '/erp' + val
    } else {
      return val
    }
  },
  // 限定不能输入空格
  keepSpace(number) {
    if (/\s/g.test(number)) {
      return number.replace(/\s/g, "")
    } else {
      return number
    }
  },
  // 保留两位小数
  reservedDigits(value) {
    if (value || value === 0) {
      value = value.toString()
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.00'
      } else {
        if (value.length - 1 - ind > 2) {
          return value.slice(0, ind + 3)
        } else if (value.length - 1 - ind < 2) {
          let index = 2 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0'
          }
          return value
        } else {
          return value
        }
      }
    }
  },
  // 计算价格保留四位
  calcPrice(num, posi = 0, str) {
    let num1 = 0;
    let fre = null
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = num * fre / fre;
    if (isNaN(num1)) {
      return 0.0000;
    } else {
      if (num1 >= str) {
        return str - 0.0001;
      } else {
        let value = num1
        value = value.toString()
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.0000'
          } else {
            if (value.length - 1 - ind > 4) {
              return value.substr(0, ind + 5)
            } else if (value.length - 1 - ind < 4) {
              let index = 4 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0'
              }
              return value
            } else {
              return value
            }
          }
        }
      }
    }
  },
  // 计算价格保留四位
  calcPrice2(num, posi = 0, str) {
    let num1 = 0;
    let fre = null
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = num * fre / fre;
    if (isNaN(num1)) {
      return 0.0000;
    } else {
      if (num1 >= str) {
        return str - 0.0001;
      } else {
        let value = num1
        value = value.toString()
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            if (value.length > 10) {
              return '-9999999.9999'
            }
            return value + '.0000'
          } else {
            if (value.length - 1 - ind > 4) {
              return value.substr(0, ind + 5)
            } else if (value.length - 1 - ind < 4) {
              let index = 4 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0'
              }
              return value
            } else {
              return value
            }
          }
        }
      }
    }
  },
  // 计算价格保留2位
  calcPriceT(num, posi, str) {
    let num1 = 0;
    // let fre = null
    // if(posi%3===1){
    //   fre = Math.pow(10,posi+1);
    // }else{
    //   fre = Math.pow(10,posi);
    // }
    num1 = Number(num);
    if (isNaN(num1)) {
      return '0.00';
    } else {
      if (num1 >= str) {
        return str - 0.01;
      } else {
        let value = num1
        value = value.toString()
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.00'
          } else {
            if (value.length - 1 - ind > 2) {
              return value.substr(0, ind + 3)
            } else if (value.length - 1 - ind < 2) {
              let index = 2 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0'
              }
              return value
            } else {
              return value
            }
          }
        }
      }
    }
  },
  // 保留小数位数且不能超出位数
  retain1(num, posi = 0, str) {
    let num1 = 0;
    let fre = null
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      if (num1 >= str) {
        return str - 0.01;
      } else {
        let value = num1
        value = value.toString()
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.00'
          } else {
            if (value.length - 1 - ind > 2) {
              return value.substr(0, ind + 3)
            } else if (value.length - 1 - ind < 2) {
              let index = 2 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0'
              }
              return value
            } else {
              return value
            }
          }
        }
      }
    }
  },
  retain2(num, posi = 0, str) {
    let num1 = 0;
    let fre = null
    if (posi % 3 === 1) {
      fre = Math.pow(10, posi + 1);
    } else {
      fre = Math.pow(10, posi);
    }
    num1 = Math.floor(num * fre) / fre;
    if (isNaN(num1)) {
      return 0;
    } else {
      if (num1 >= str) {
        return str - 0.001;
      } else {
        let value = num1
        value = value.toString()
        if (value) {
          let ind = value.toString().indexOf('.');
          if (ind < 0) {
            return value + '.000'
          } else {
            if (value.length - 1 - ind > 3) {
              return value.substr(0, ind + 4)
            } else if (value.length - 1 - ind < 3) {
              let index = 3 - (value.length - 1 - ind);
              for (let i = 0; i < index; i++) {
                value = value + '0'
              }
              return value
            } else {
              return value
            }
          }
        }
      }
    }
  },

  // 计算位数保留四位
  haveFour(value) {
    if (value || value === 0) {
      value = value.toString()
      let ind = value.toString().indexOf('.');
      if (ind < 0) {
        return value + '.0000'
      } else {
        if (value.length - 1 - ind > 4) {
          return value.slice(0, ind + 5)
        } else if (value.length - 1 - ind < 4) {
          let index = 4 - (value.length - 1 - ind);
          for (let i = 0; i < index; i++) {
            value = value + '0'
          }
          return value
        } else {
          return value
        }
      }
    }
  },
};